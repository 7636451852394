import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './books.module.css';

const BooksPage = ({data}) => (
  <Layout>
    <SEO title="Books" />
    <h2>Books</h2>
    <p>
      This is a collection of books which have influenced how I think about
      design, technology, business, and life in general. I don&#39;t really read that
      often but having a books page makes me seem like an intellectual to people who
      have never met me.
      They are in no particular order.
    </p>
    {data.allFile.edges.map((edge) =>
      <div className={styles.book} key={edge.node.id}>
        <Img fluid={edge.node.childImageSharp.fluid} />
      </div>
    )}
  </Layout>
);

BooksPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query BooksQuery {
     allFile(filter: { relativeDirectory: { eq: "books" } }) {
       edges {
         node {
           id
           childImageSharp {
             fluid(maxWidth: 300) {
               ...GatsbyImageSharpFluid
             }
           }
         }
       } 
     }
   }
`;

export default BooksPage;
